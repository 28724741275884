import JustValidate from 'just-validate';

// Styling fixes form

document.addEventListener("DOMContentLoaded", function() {
    const radioButtons = document.querySelectorAll('.umbraco-forms-form .radiobuttonlist input[type="radio"]');
    const checkBoxes = document.querySelectorAll('.umbraco-forms-form input[type="checkbox"]');
    const fieldsets = document.querySelectorAll('.umbraco-forms-fieldset');
    const fields = document.querySelectorAll('.umbraco-forms-field');
    const captions = document.querySelectorAll('.umbraco-forms-caption');
    const infotextFields = document.querySelectorAll('.titleanddescription');

    if (radioButtons) {
        for (let index = 0; index < radioButtons.length; index++) {
            const element = radioButtons[index];
            if (element) {
                element.setAttribute('style', 'width: 1.75rem !important; height: 1.75rem !important; border: 1px solid #c8c6c4 !important; display: inline-block !important');
            }
        }
    }

    if (checkBoxes) {
        for (let index = 0; index < checkBoxes.length; index++) {
            const element = checkBoxes[index];
            if (element) {
                element.setAttribute('style', 'width: 1.75rem !important; height: 1.75rem !important; border: 1px solid #c8c6c4 !important; display: inline-block !important');
            }
        }
    }
    
    if (fields) {
        for (let index = 0; index < fields.length; index++) {
            const element = fields[index];
            if (element) {
                element.setAttribute('style', 'padding: 0 !important; margin: 0 !important; border: 0 !important;');
            }
        }
    }
    
    if (fieldsets) {
        for (let index = 0; index < fieldsets.length; index++) {
            const element = fieldsets[index];
            if (element) {
                element.setAttribute('style', 'padding-top: 0 !important; margin: 0 !important; border: 0 !important;');
            }
        }
    }
    
    if (captions) {
        for (let index = 0; index < captions.length; index++) {
            const element = captions[index];
            if (element) {
                element.setAttribute('style', 'font-size: 1.75rem !important; line-height: 2.0625rem !important;');
            }
        }
    }

    if (infotextFields) {
        for (let index = 0; index < infotextFields.length; index++) {
            const element = infotextFields[index];
            
            if (element) {
                const legend = element.nextElementSibling.querySelector('legend');

                if (legend) {
                    legend.classList.add('visually-hidden');
                } else {
                    const label = element.nextElementSibling.querySelector('label');

                    if (label) {
                        label.classList.add('visually-hidden');
                    }
                }
            }
        }
    }
});

// Basic functions
function pad(n) {
    return (n.length < 2) ? ("0" + n) : n;
}

// Update RelationData
const streetInputProfileEdit = document.querySelector('#ckStreet input');
const cityInputProfileEdit = document.querySelector('#ckCity input');
const zipCodeInputProfileEdit = document.querySelector('#ckZip input');
const houseNrInputProfileEdit = document.querySelector('#ckHouseNo input');

if (document.getElementById('ckProfileForm')) {

    const form = new JustValidate('#ckProfileForm', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        form
        .addField('#ckIBAN', [
            {
                rule: 'maxLength',
                value: 18,
                errorMessage: 'De lengte van het IBAN nummer is te lang'
            },
            {
                rule: 'customRegexp',
                value: 'NL[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){2}([0-9]{2})\s?',
                errorMessage: 'De ingevulde waarde voor het IBAN nummer is incorrect'
            }
        ])
        .addField('#ckMob', [
            {
                rule: 'maxLength',
                value: 10,
                errorMessage: 'De lengte van het mobiele telefoonnummer is te lang'
            },
            {
                rule: 'customRegexp',
                value: '06[0-9]{8}',
                errorMessage: 'De ingevulde waarde voor het mobiele telefoonnummer is incorrect'
            }
        ])
        .addField('#ckTel', [
            {
                rule: 'customRegexp',
                value: '[0-9]',
                errorMessage: 'De ingevulde waarde voor het telefoonnummer is incorrect'
            }
        ])
        .addField('#ckHouseNo', [
            {
                rule: 'customRegexp',
                value: '[0-9]',
                errorMessage: 'De ingevulde waarde voor het huisnummer is incorrect'
            }
        ])
        .addField('#ckZip', [
            {
                rule: 'customRegexp',
                value: '^[1-9][0-9]{3}[A-Za-z]{2}$',
                errorMessage: 'De ingevulde waarde voor de postcode is incorrect'
            }
        ])
        .addField('#ckAgreeToPrivacy', [{
            rule: 'required',
            errorMessage: 'U dient akkoord te gaan met de privacyverklaring'
        }])
        .onSuccess(( event ) => {
            const submitButtonText = document.getElementById('btntext');
            if (submitButtonText) {
                const loader = document.getElementById('btnloader');

                if (loader){
                    const submitButton = document.getElementById('submitProfileEdit');
                    submitButton.setAttribute('disabled',true); 
                    loader.classList.remove('d-none');
                    submitButtonText.innerText = 'Even geduld...';
                }
            }
            UpdateRelationData();
        });

        if (streetInputProfileEdit && cityInputProfileEdit) {
            checkZipCodeProfileEdit();
            zipCodeInputProfileEdit.addEventListener("keyup", checkZipCodeProfileEdit);
            houseNrInputProfileEdit.addEventListener("keyup", checkZipCodeProfileEdit);
        }
    }
}

function checkZipCodeProfileEdit() {
    const houseNoAddInputProfileEdit = document.querySelector('#ckHousNoAdd input');

    const zipCode = zipCodeInputProfileEdit.value;
    const houseNr = houseNrInputProfileEdit.value;
    const houseNoAdd = houseNoAddInputProfileEdit.value;

    if (zipCode && houseNr) {
        if (zipCode.match(new RegExp(/^[1-9][0-9]{3}[A-Za-z]{2}$/))) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {

                var url = "/umbraco/api/korenFrontend/ZipCodeLookup"
                var zipcodeArg = "zipcode=" + zipCode;
                var housenumberArg = "housenumber=" + houseNr;
                url += "?" + zipcodeArg + "&" + housenumberArg;
                if (houseNoAdd) {
                    url += "&" + "housenumberAddition=" + houseNoAdd;
                }

                fetch(url).then(res =>
                    res.json()).then(d => {
                        if (d) {
                            streetInputProfileEdit.value = d.straatnaam;
                            cityInputProfileEdit.value = d.plaatsnaam;
                        }
                        else {
                            streetInputProfileEdit.value = "Onbekend";
                            cityInputProfileEdit.value = "Onbekend";
                        }
                    })
            }, 500);
        
        }
        else {
            streetInputProfileEdit.value = "";
            cityInputProfileEdit.value = "";
        }
    }
}

function UpdateRelationData() {
    var profileInfo = JSON.parse(document.getElementById('profileInfo').value);
    profileInfo.first_name = document.getElementById('ckFName').value;
    profileInfo.gender = document.querySelector('input[name=ckGender]:checked').value;
    profileInfo.initials = document.getElementById("ckInitials").value;
    profileInfo.first_name = document.getElementById("ckFName").value;
    profileInfo.birth_name = document.getElementById("ckBName").value;
    profileInfo.date_of_birth = document.getElementById("ckDoBY").value + "-" + pad(document.getElementById("ckDoBM").value) + "-" + pad(document.getElementById("ckDoBD").value)
    profileInfo.telephone_number = document.getElementById("ckTel").value;
    profileInfo.mobilephone_number = document.getElementById("ckMob").value;
    profileInfo.zip_code = document.getElementById("ckZip").value;
    profileInfo.housenumber = document.getElementById("ckHouseNo").value;
    profileInfo.housenumber_addition = document.getElementById("ckHouseNoAdd").value;
    profileInfo.iban = document.getElementById("ckIBAN").value;

    fetch("/umbraco/api/KorenFrontend/UpdateRelationData", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(profileInfo)
    }).then(response => response.json())
        .then(response => {
            if (response) {
                if (response && response.success) {
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
                }
                else {
                    var err = encodeURIComponent(response.korenErrorMessage.message);
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?error=" + err;
                }
            }
        });
}

// Request Username Change
if (document.getElementById('ckChangeEmailAddressForm')) {

    const form = new JustValidate('#ckChangeEmailAddressForm', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        form
            .addField('#ckNewEmailAddress', [
                {
                    rule: 'email',
                    errorMessage: 'De ingevulde waarde voor het emailadres is incorrect'
                }
            ]).onSuccess(( event ) => {
                RequestUsernameChange();
            });
    }
}

function RequestUsernameChange() {
    var requestObject = {};
    requestObject.Username = document.getElementById('ckNewEmailAddress').value;
    requestObject.Password = document.getElementById('ckPassword').value;

    fetch("/umbraco/api/KorenFrontend/RequestUsernameChange", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestObject)
    }).then(response => response.json())
    .then(response => {
        if (response && response.success) {
            window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
        }
        else {
            var err = encodeURIComponent(response.korenErrorMessage.message);
            window.location.href = location.protocol + '//' + location.host + location.pathname + "?error=" + err;
        }
    });
}

// ChangePassword
if (document.getElementById('ckPasswordChangeForm')) {

    const form = new JustValidate('#ckPasswordChangeForm', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        form
            .onSuccess(( event ) => {
                ChangePassword();
            });
    }
}

function ChangePassword() {
    var requestObject = {};
    requestObject.PasswordCurrent = document.getElementById('ckCurrentPassword').value;
    requestObject.PasswordNew = document.getElementById('ckNewPassword').value;

    fetch("/umbraco/api/KorenFrontend/ChangePassword", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestObject)
    }).then(response => response.json())
        .then(response => {
            if (response && response.success) {
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
            }
            else {
                var err = encodeURIComponent(response.korenErrorMessage.message);
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?error=" + err;
            }
    });
}

// SetLabels
if (document.getElementById('ckSetLabelsForm')) {

    const form = new JustValidate('#ckSetLabelsForm', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        form
            .onSuccess(( event ) => {
                SetLabels();
            });
    }
}

function SetLabels() {
    const radiobuttons = document.querySelectorAll('input[type="radio"]');
    const checkedRadiobutton = document.querySelector('input[type="radio"]:checked');

    if (radiobuttons && checkedRadiobutton) {
        var requestArray = [];

        for(let i = 0; i < radiobuttons.length - 1; i++) {
            var radiobutton = radiobuttons[i];
            var labelRequest = {};
            labelRequest.Id = radiobutton.value;
            labelRequest.Active = radiobutton.id == checkedRadiobutton.id;
            requestArray.push(labelRequest);
        }
    
        fetch("/umbraco/api/KorenFrontend/SetLabels", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestArray)
        }).then(response => response.json())
            .then(response => {
                if (response && response.success) {
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
                }
                else {
                    var err = encodeURIComponent(response.korenErrorMessage.message);
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?error=" + err;
                }
        });
    }
}

// Forgot password
if (document.getElementById('ckForgotPasswordForm')) {

    const form = new JustValidate('#ckForgotPasswordForm', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        form
        .addField('#ckEmailAddress', [
            {
                rule: 'required',
                errorMessage: 'Het emailadres is verplicht'
            },
            {
                rule: 'email',
                errorMessage: 'De ingevulde waarde voor het emailadres is incorrect'
            }
        ])
            .onSuccess(( event ) => {
                ForgotPassword();
            });
    }
}

function ForgotPassword() {
    const username = document.getElementById('ckEmailAddress').value;

    if (username) {

        fetch("/umbraco/api/KorenFrontend/RequestPasswordResetForUser", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(response => response.json())
            .then(response => {
                if (response && response.success) {
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
                }
                else {
                    var err = encodeURIComponent(response.korenErrorMessage.message);
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?error=" + err;
                }
        });
    }
}

// Reset password
if (document.getElementById('ckPasswordResetForm')) {

    const form = new JustValidate('#ckPasswordResetForm', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        form
        .addField('#ckNewPassword', [
            {
                rule: 'required',
                errorMessage: 'Vul een nieuw wachtwoord in'
            },
            {
                rule: 'strongPassword',
                errorMessage: 'Het wachtwoord moet minimaal 8 karakters hebben, met minimaal 1 cijfer, 1 hoofdletter en een speciaal karakter (! $ % * ? @).'
            }
        ])
        .addField('#ckNewPasswordCheck', [
            {
                rule: 'required',
                errorMessage: 'Vul nogmaals uw nieuwe wachtwoord in'
            },
            {
                rule: 'strongPassword',
                errorMessage: 'Het wachtwoord moet minimaal 8 karakters hebben, met minimaal 1 cijfer, 1 hoofdletter en een speciaal karakter (! $ % * ? @).'
            }
        ]).onSuccess(( event ) => {
                ResetPassword();
            });
    }
}

function ResetPassword() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const password = document.getElementById('ckNewPassword').value;

    if (token) {
        var passwordRequestObject = {};
        passwordRequestObject.Token = token;
        passwordRequestObject.Password = password;

        fetch("/umbraco/api/KorenFrontend/ResetPassword", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(passwordRequestObject)
        }).then(response => response.json())
            .then(response => {
                if (response && response.success) {
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
                }
                else {
                    var err = encodeURIComponent(response.korenErrorMessage.message);
                    window.location.href = location.protocol + '//' + location.host + location.pathname + "?token=" + token + "&error=" + err;
                }
        });
    }
}

// Login With Card Request
if (document.getElementById('ckLoginWithCardRequest')) {

    const form = new JustValidate('#ckLoginWithCardRequest', {
        validateBeforeSubmitting: true
    });
    
    if (form) {
        const passwordField = document.getElementById('ckPassword');
        const passwordCheckField = document.getElementById('ckPasswordCheck');
    
        if (passwordField && passwordCheckField) {
            passwordField.addEventListener("keydown", (event) => {
                if (event.isComposing || event.keyCode === 229) {
                  return;
                }
                if (passwordField.value != passwordCheckField.value) {
                    form.showErrors({'ckPassword': 'De ingevoerde wachtwoorden komen niet overeen.'})
                }
            });

            passwordCheckField.addEventListener("keydown", (event) => {
                if (event.isComposing || event.keyCode === 229) {
                  return;
                }
                if (passwordField.value != passwordCheckField.value) {
                    form.showErrors({'ckPasswordCheck': 'De ingevoerde wachtwoorden komen niet overeen.'})
                }
            });
        }

        form
        .addField('#ckCardNumber', [
            {
                rule: 'required',
                errorMessage: 'Vul uw CareynKaart nummer in.'
            }
        ])
        .addField('#ckZipcode', [
            {
                rule: 'customRegexp',
                value: '^[1-9][0-9]{3}[A-Za-z]{2}$',
                errorMessage: 'De ingevulde waarde voor de postcode is incorrect.'
            }
        ])
        .addField('#ckHousenumber', [
            {
                rule: 'customRegexp',
                value: '^[0-9]*$',
                errorMessage: 'Huisnummer zonder toevoeging, enkel het huisnummer is nodig.'
            }
        ])
        .addField('#ckAgreeToPrivacy', [{
            rule: 'required',
            errorMessage: 'U dient akkoord te gaan met de privacyverklaring.'
        }])
        .addField('#ckPassword', [
            {
                rule: 'required',
                errorMessage: 'Vul uw wachtwoord in'
            },
            {
                rule: 'strongPassword',
                errorMessage: 'Het wachtwoord moet minimaal 8 karakters hebben, met minimaal 1 cijfer, 1 hoofdletter en een speciaal karakter (! $ % * ? @).'
            }
        ])
        .addField('#ckPasswordCheck', [
            {
                rule: 'required',
                errorMessage: 'Vul nogmaals uw wachtwoord in'
            },
            {
                rule: 'strongPassword',
                errorMessage: 'Het wachtwoord moet minimaal 8 karakters hebben, met minimaal 1 cijfer, 1 hoofdletter en een speciaal karakter (! $ % * ? @).'
            }
        ]).onSuccess(( event ) => {
            const submitButtonText = document.getElementById('btntext');
            if (submitButtonText) {
                const loader = document.getElementById('btnloader');

                if (loader){
                    const submitButton = document.getElementById('submitLoginWithCardRequest');
                    submitButton.setAttribute('disabled',true); 
                    loader.classList.remove('d-none');
                    submitButtonText.innerText = 'Even geduld...';
                }
            }
            LoginWithCardRequest();
            });
    }
}

function LoginWithCardRequest() {
    var loginWithCardRequestObject = {};
    loginWithCardRequestObject.accountSearchData = {};
    var accountSearchData = {};

    loginWithCardRequestObject.emailaddress = document.getElementById('ckEmailAddress').value;
    loginWithCardRequestObject.password = document.getElementById('ckPassword').value;
    
    accountSearchData.customer_id = document.getElementById('ckCardNumber').value;
    accountSearchData.housenumber = document.getElementById('ckHousenumber').value;
    accountSearchData.zip_code = document.getElementById('ckZipcode').value;

    loginWithCardRequestObject.accountSearchData = accountSearchData;

    fetch("/umbraco/api/KorenFrontend/CreateAccountExistingCustomer", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginWithCardRequestObject)
    }).then(response => response.json())
        .then(response => {
            if (response && response.success) {
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?success=true";
            }
            else {
                var err = encodeURIComponent(response.korenErrorMessage.message);
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?error=" + err;
            }
    });
}

// Zipcode lookup
const streetInput = document.querySelector('.straat input');
const cityInput = document.querySelector('.woonplaats input');
const zipCodeInput = document.querySelector('.postcode input');
const houseNrInput = document.querySelector('.huisnummer input');

if (document.getElementsByClassName('lidworden').length > 0) {
    if (streetInput && cityInput) {
        checkZipCode();
        zipCodeInput.addEventListener("keyup", checkZipCode);
        houseNrInput.addEventListener("keyup", checkZipCode);
    }
}

function checkZipCode() {
    const houseNoAddInput = document.querySelector('.huisnummertoevoeging input');

    const zipCode = zipCodeInput.value;
    const houseNr = houseNrInput.value;
    const houseNoAdd = houseNoAddInput.value;

    if (zipCode && houseNr) {
        if (zipCode.match(new RegExp(/^[1-9][0-9]{3}[A-Za-z]{2}$/))) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {

                var url = "/umbraco/api/korenFrontend/ZipCodeLookup"
                var zipcodeArg = "zipcode=" + zipCode;
                var housenumberArg = "housenumber=" + houseNr;
                url += "?" + zipcodeArg + "&" + housenumberArg;
                if (houseNoAdd) {
                    url += "&" + "housenumberAddition=" + houseNoAdd;
                }

                fetch(url).then(res =>
                    res.json()).then(d => {
                        if (d) {
                            streetInput.value = d.straatnaam;
                            cityInput.value = d.plaatsnaam;
                        }
                        else {
                            streetInput.value = "Onbekend";
                            cityInput.value = "Onbekend";
                        }
                    })
            }, 500);
        
        }
        else {
            streetInput.value = "";
            cityInput.value = "";
        }
    }
}

// Test credentials
var timeout = null;
const emailInput = document.querySelector('.emailadresgebruikersnaamaanmaken input');
const passwordInput = document.querySelector('.wachtwoordaanmaken input');

if (document.getElementsByClassName('lidworden').length > 0) {
    if (emailInput && passwordInput) {
        checkCredentials();
        emailInput.addEventListener("keypress", checkCredentials);
        passwordInput.addEventListener("keypress", checkCredentials);
    }
}

function checkCredentials() {
    const email = emailInput.value;
    const password = passwordInput.value;

    if (email && password) {
        clearTimeout(timeout);
            timeout = setTimeout(function () {

                var credentialModelObject = {};
                credentialModelObject.emailaddress = email;
                credentialModelObject.password = password;

                fetch("/umbraco/api/KorenFrontend/TestCredentialsAcceptable", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentialModelObject)
                }).then(response => response.json())
                    .then(response => {
                        const passwordId = passwordInput.id;
                        const errorSpan = document.querySelector('[data-valmsg-for="' + passwordId + '"]');

                        if (response && response.success) {
                            if (errorSpan) {
                                errorSpan.className = 'field-validation-valid';
                                errorSpan.innerText = '';
                                document.getElementById(passwordId).className = 'text input-validation-valid';
                            }
                        } else {
                            if (errorSpan) {
                                var err = encodeURIComponent(response.korenErrorMessage.message);
                                errorSpan.className = 'field-validation-error';
                                errorSpan.innerText = err.replaceAll('%20', ' ');
                                document.getElementById(passwordId).className = 'text input-validation-error';
                            }
                        }
                    })
            }, 500);
    }
}

// Subscribe for newsletter anonymous
if (document.getElementById('ckAutoSubscribeNewsletter')) {

    const form = new JustValidate('#ckAutoSubscribeNewsletter', {
        validateBeforeSubmitting: true
    });
    if (form) {
        form
        .addField('#ckEmailAddress', [
            {
                rule: 'required',
                errorMessage: 'Het emailadres is verplicht'
            },
            {
                rule: 'email',
                errorMessage: 'De ingevulde waarde voor het emailadres is incorrect'
            }
        ])
        .addField('#ckConsent', [
            {
                rule: 'required',
                errorMessage: 'Je moet akkoord gaan met de privacyverklaring'
            }
        ])
            .onSuccess(( event ) => {
                AutoSubscribe();
            });
    }
}

function AutoSubscribe() {
    const submitButtonText = document.getElementById('btntext');
    const loader = document.getElementById('btnloader');

    if (loader){
        const submitButton = document.getElementById('submitAutoSubscription');
        submitButton.setAttribute('disabled',true); 
        loader.classList.remove('d-none');
        submitButtonText.innerText = 'Even geduld...';
    }

    const emailAddress = document.getElementById('ckEmailAddress').value;
    const mailingListId = document.getElementById('ckMailingListId').value;
    const anonymousMailingListId = document.getElementById('ckAnonymousMailingListId').value;

    var subscriptionModelObject = {};
    subscriptionModelObject.emailaddress = emailAddress;
    subscriptionModelObject.mailingListId = mailingListId;
    subscriptionModelObject.anonymousMailingListId = anonymousMailingListId;

    fetch("/umbraco/api/KorenFrontend/AutoSubscribe", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(subscriptionModelObject)
    }).then(response => response.json())
        .then(response => {
            document.getElementById('ckAutoSubscribeNewsletter').classList.add('d-none');
            if (response && response.success) {
                document.getElementById('autoSubscriptionSucces').classList.remove('d-none');
            }
            else {
                document.getElementById('autoSubscriptionError').classList.remove('d-none');
            }
    });
}


// Subscribe for newsletter for members
if (document.getElementById('ckSubscribeNewsletter')) {

    const form = new JustValidate('#ckSubscribeNewsletter', {
        validateBeforeSubmitting: true
    });
    if (form) {
        form.onSuccess(( event ) => {
            Subscribe();
        });
    }
}

function Subscribe() {
    const submitButtonText = document.getElementById('btntext');
    const loader = document.getElementById('btnloader');
    const submitButton = document.getElementById('submitSubscription');

    if (loader){
        submitButton.setAttribute('disabled',true); 
        loader.classList.remove('d-none');
        submitButtonText.innerText = 'Even geduld...';
    }

    const mailingListId = document.getElementById('ckMailingListId').value;
    const checked = document.getElementById('ckNewsletter').checked;

    var subscriptionModelObject = {};
    subscriptionModelObject.checked = checked;
    subscriptionModelObject.mailingListId = mailingListId;

    fetch("/umbraco/api/KorenFrontend/Subscribe", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(subscriptionModelObject)
    }).then(response => response.json())
        .then(response => {
            loader.classList.add('d-none');
            submitButton.setAttribute('disabled',false); 
            submitButtonText.innerText = 'Wijzigen';
            if (response && response.success) {
                document.getElementById('subscriptionSucces').classList.remove('d-none');
            }
            else {
                document.getElementById('subscriptionError').classList.remove('d-none');
            }
    });
}