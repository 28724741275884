document.addEventListener('DOMContentLoaded', function() {
    const toggleButtons = document.querySelectorAll('.js-toggleBtn');
    
    toggleButtons.forEach(toggleButton => {
      const extraItems = toggleButton.previousElementSibling.querySelectorAll('.js-extraitem');
      
      if (extraItems.length > 0) {
        toggleButton.classList.remove('hidden');
      }
      
      toggleButton.addEventListener('click', function() {
        this.classList.toggle('active');
        extraItems.forEach(item => {
          item.classList.toggle('hidden');
        });
        
        const buttonText = toggleButton.textContent;
        if (buttonText === 'Meer tonen') {
          toggleButton.textContent = 'Minder tonen';
        } else {
          toggleButton.textContent = 'Meer tonen';
        }
      });
    });
});