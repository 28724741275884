document.addEventListener("DOMContentLoaded", function() {

// When mobile menu is open, body scroll is hidden
if (document.getElementById('menuOpen')) {
  document.getElementById('menuOpen').addEventListener('click', function () {
    document.documentElement.classList.toggle('mobile-menu-open');
  });
}

// When the dropdown is open, the active state is currently hidden.
  document.addEventListener('click', function(event) {
    if (event.target.classList.contains('dropdown-toggle')) {
      const parentUl = event.target.closest('.c-navbar_nav');
      parentUl.classList.toggle('h-dropdown', event.target.classList.contains('show'));
    } else {
      document.querySelectorAll('.c-navbar_nav').forEach(ul => {
        ul.classList.remove('h-dropdown');
      });
    }
  });
  
});

// sticky logo animate by scroll
window.addEventListener('scroll', function() {
    const header = document.querySelector('.js-navigation');
    if (window.scrollY > 50) {
        header.classList.add('active');
    } else {
        header.classList.remove('active');
    }
});