import { Modal } from '../../../../node_modules/bootstrap/dist/js/bootstrap.esm.js'

document.addEventListener("DOMContentLoaded", function() {
  const modals = document.querySelectorAll('.modal');
  modals.forEach(function(modal) {
    modal.addEventListener('shown.bs.modal', function () {
      if (modal) {
        modal.classList.add('openform');
      }
      const youtubeIframe = modal.querySelector('iframe[data-youtube]');
      if (youtubeIframe) {
        const videoSrc = youtubeIframe.getAttribute('src');
        const url = new URL(videoSrc);
        const searchParams = url.searchParams;
        searchParams.set('autoplay', '1');
        youtubeIframe.setAttribute('src', url.toString());
      }
    });
    modal.addEventListener('hidden.bs.modal', function () {
      const youtubeIframe = modal.querySelector('iframe[data-youtube]');
      if (youtubeIframe) {
        const videoSrc = youtubeIframe.getAttribute('src');
        const url = new URL(videoSrc);
        const searchParams = url.searchParams;
        searchParams.set('autoplay', '0');
        youtubeIframe.setAttribute('src', url.toString());
      }
    });
  });
});

let myModalElement = document.querySelector('.openform');
if (myModalElement) {
  let newModal = new Modal(myModalElement);
  newModal.show();
}

let myModalCookie = document.querySelector('.openCookieModal');
if (myModalCookie) {
  let newModal = new Modal(myModalCookie);
  newModal.show();
}


// lid worden form (het gaat puur om styling van de progressBar met behlp van next button, zonder validatie etc, nog niet perfect)
document.addEventListener("DOMContentLoaded", function () {
  const legend = document.querySelector('.umbraco-forms-fieldset legend');
  if (legend) {
    legend.classList.add('d-none');
  }
  const progressBar = document.getElementById("progressBar");
  const progressBarCount = document.getElementById("progressBarCount");
  const countOfSteps = document.getElementById('countOfSteps');
  // const nextButtons = document.getElementsByClassName("next");
  // const prevButtons = document.getElementsByClassName("prev");

  if (progressBar && progressBarCount && countOfSteps) {
    // for (const button of nextButtons) {
    //   button.addEventListener("click", function (e) {
    //     e.preventDefault();
    //     goToNextForm();
    //   });
    // }

    // for (const button of prevButtons) {
    //   button.addEventListener("click", function (e) {
    //     e.preventDefault();
    //     goToPrevForm();
    //   });
    // }

    function updateProgressBar() {
      const currentStep = document.querySelector('.umbraco-forms-fieldset legend').innerHTML;
      const progress = (currentStep / countOfSteps.value) * 100;
    
      progressBar.style.width = progress + "%";
    
      if (progress !== 1) {
        progressBarCount.innerText = `${currentStep}/${countOfSteps.value}`;
      }

      if (progress === 0) {
        progressBar.classList.add("first");
        progressBar.style.width = 100 + "px";
      } else {
        progressBar.classList.remove("first");
        progressBar.style.width = progress + "%";
      }

      if (Math.round(progress) === 100) {
        progressBar.classList.add("done");
      }
    
      // forms.forEach(form => form.classList.remove("show"));
      
      // if (currentFormIndex < forms.length - 1) {
      //   forms[currentFormIndex + 1].classList.add("show");
      // } else {
      //   const lastForm = forms[currentFormIndex].closest('.modal-body');
      //   if (lastForm) {
      //     lastForm.classList.add("last");
      //   }
      // }
    }

    // function goToNextForm() {
    //   updateProgressBar();
    // }

    // function goToPrevForm() {
    //   // Implementeer logica voor teruggaan naar vorig formulier
    // }

    updateProgressBar();
  }
});






