import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function sliderContent() {
    const slider = document.getElementById('jsSliderExperience');
    const options = {
      container: '#jsSliderExperience',
      edgePadding: 0,
      gutter: 20,
      items: 1.2,
      slideBy: 'page',
      autoplay: false,
      speed: 400,
      loop: true, 
      touch: true,
      controls: false,
      slideBy: false,
      mouseDrag: true,
      responsive: {
        767: {
            items: 2  
        },
        992: {
            items: 3
        }
      }
    };
  
    if (slider) {
      const sliderContent = tns(options);
      return sliderContent;
    }
  }
  
  sliderContent();