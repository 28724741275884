function AddLoadMoreEventGeneralSearch() {
  const obj = document.getElementById('js-show-more-btn');
  if (obj !== undefined && obj !== null) {
    obj.addEventListener('click', function (e) {
      e.stopImmediatePropagation();
      const params = new URLSearchParams(window.location.search);
      let more = Number(params.get('more')) + 1;
      params.set("more", more);
      var newRelativePathQuery = window.location.pathname + '?' + params.toString();
      history.pushState(null, '', newRelativePathQuery);
      LoadGeneralSearchResults(obj)
    }, false);
  }
}

function LoadGeneralSearchResults(button, amount) {
  const results = document.getElementsByClassName('c-searchresults_link');
  let items = button.getAttribute('data-items');
  const more = amount !== undefined ? amount : button.getAttribute('data-more');
  items = Number(items) + Number(more);
  button.setAttribute('data-items', items);
  
  if (items > results.length) 
  {
    items = results.length;
    button.classList.add('d-none');
  }
  
  for (let i = 0; i < items; i++) {
    results[i].parentElement.classList.remove('d-none');
  }

  if (items >= results.length) {
    button.classList.add('d-none');
  }
}

function ShowMoreResultsBasedOnQuerystring() {
  const params = new URLSearchParams(window.location.search);
  const more = Number(params.get('more'));
  const obj = document.getElementById('js-show-more-btn');
  if (obj !== undefined && obj !== null) {
    const buttonMore = Number(obj.getAttribute('data-more'));
    if (more !== null) {
      const amount = buttonMore * more;
      LoadGeneralSearchResults(obj, amount)
    }
  }
}

AddLoadMoreEventGeneralSearch();
ShowMoreResultsBasedOnQuerystring();